/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { AxiosPromise } from 'axios'
import {
  IAdditionalContactsPayload,
  IBankruptDataItems,
  IPassportDataUpdate,
  IPersonalDataUpdate,
  IRegistrationDataUpdate,
  IResidenceDataUpdate,
  IUserDetailsUpdateData,
} from 'features/loan/types'

import { ServiceBase } from './serviceBase'

const loanFields = [
  'loans.loan_date_create',
  // 'requests_count',
  'loans.loan_id',
  'loans.loan_number',
  'passport.first_name',
  'passport.last_name',
  'passport.middle_name',
  'users.login',
  'loans.loan_ask_sum',
  'passport.number',
  'passport.serial',
  // 'loans.loan_ask_period',
  // 'loans.loan_sum',
  'utm_source',
  // 'registration_address',
  'closed_count',
  'residence_address',
  'loan_statuses.status_name',
  'admin_username',
  'loans.product_id',
  'loans.loan_permitted_sum',
  'loan_statuses.status_date',
  // 'usage_days',
]

const operatorLoanFields = ['passport.number', 'passport.serial']

const loanHistoryFields = [
  'loans.loan_date_create',
  'loans.loan_number',
  'loans.loan_sum',
  'loans.loan_period',
  'loans.loan_issue_date',
  'loans.product_id',
  'overdue_days',
  'usage_days',
  'loan_statuses.status_name',
]

export class ProjectLoansService extends ServiceBase {
  protected static LOAN_PATH = 'loans'

  protected static VIDEO_PATH = 'video'

  protected static IMAGES_PATH = 'images'

  protected static USERS_PATH = 'users'

  protected static EXTERNAL_PATH = 'external_sources'

  protected static COUNT_PAGE_REQUEST = 20

  public static getLoans(
    iteration: number,
    type: string,
    filters: any,
    subject?: string
  ): AxiosPromise {
    const { loan_last_status_date, ...restFilters } = filters

    let fields = loanFields
    if (subject === 'operator') fields = [...loanFields, ...operatorLoanFields]
    const data = JSON.stringify({
      fields,
      ...restFilters,
    })

    const types = {
      all: '',
      unassigned: '_unassigned',
      pre_approved: '_unassigned',
      processing: '_processing',
    }

    return this.post(
      `${this.LOAN_PATH}/get${types[type]}?page_size=${this.COUNT_PAGE_REQUEST}&p=${iteration}${
        (type === 'unassigned' || type === 'processing' || type === 'pre_approved') &&
        loan_last_status_date
          ? `&status_date_sort=${loan_last_status_date}`
          : ''
      }${type === 'unassigned' ? `&loan_status=setting_a_limit` : ''}${
        type === 'pre_approved' ? `&loan_status=pre_approved` : ''
      }`,
      data
    )
  }

  public static getLoansByUserId(userIds): AxiosPromise {
    const data = JSON.stringify({
      fields: [...loanFields, 'overdue_days'],
      user_ids: userIds,
    })
    return this.post(`${this.LOAN_PATH}/get?page_size=${this.COUNT_PAGE_REQUEST}&p=1`, data)
  }

  public static getDetailedLoansByUserId(userIds): AxiosPromise {
    const data = JSON.stringify({
      fields: loanHistoryFields,
      user_ids: userIds,
    })
    return this.post(`${this.LOAN_PATH}/get?page_size=${this.COUNT_PAGE_REQUEST}&p=1`, data)
  }

  public static getLoansByLoanId(loanIds): AxiosPromise {
    const data = JSON.stringify({
      fields: [...loanFields, 'overdue_days'],
      loan_ids: loanIds,
    })
    return this.post(`${this.LOAN_PATH}/get?page_size=${this.COUNT_PAGE_REQUEST}&p=1`, data)
  }

  public static getLoansCount(): AxiosPromise {
    return this.get(`${this.LOAN_PATH}/loans_count`)
  }

  public static getProcessingLoansCount(): AxiosPromise {
    return this.get(`${this.LOAN_PATH}/processing_count`)
  }

  public static getUnassignedLoansCount(): AxiosPromise {
    return this.get(`${this.LOAN_PATH}/unassigned_count`)
  }

  public static getPreApprovedLoansCount(): AxiosPromise {
    return this.get(`${this.LOAN_PATH}/pre_approved_count`)
  }

  public static getAutoCheck(loanId): AxiosPromise {
    return this.get(`${this.LOAN_PATH}/auto-check/${loanId}`)
  }

  public static getPersonalData(userId): AxiosPromise {
    return this.get(`${this.USERS_PATH}/personal-data/${userId}`)
  }

  public static getFemidaPhone(props): AxiosPromise {
    return this.post(
      `data/femida/mapping/name-phone/${props.userId}?number=${props.number}&force=${
        props.force
      }&no_cache_ok=${!props.force}`,
      props.data
    )
  }

  public static getFemidaPassport(props): AxiosPromise {
    return this.post(
      `data/femida/mapping/name-passport/${props.userId}?number=${props.number}&force=${
        props.force
      }&no_cache_ok=${!props.force}`,
      props.data
    )
  }

  public static getFemidaScoringLegal(props): AxiosPromise {
    return this.post(
      `data/femida/scoring/legal/${props.userId}?number=${props.number}&force=${
        props.force
      }&no_cache_ok=${!props.force}`,
      props.data
    )
  }

  public static getFemidaScoringOneTimePhone(props): AxiosPromise {
    return this.post(
      `data/femida/scoring/one-time-phone/${props.userId}?number=${props.number}&force=${
        props.force
      }&no_cache_ok=${!props.force}`,
      props.data
    )
  }

  public static getFemidaScoringFraudPhone(props): AxiosPromise {
    return this.post(
      `data/femida/scoring/fraud-phone/${props.userId}?number=${props.number}&force=${
        props.force
      }&no_cache_ok=${!props.force}`,
      props.data
    )
  }

  public static getCustomerAgentData(customerId): AxiosPromise {
    return this.get(`${this.USERS_PATH}/customer_agent/${customerId}`)
  }

  public static blockLk(userId): AxiosPromise {
    return this.post(`${this.USERS_PATH}/block_lk`, {
      user_id: userId,
    })
  }

  public static unBlockLk(userId): AxiosPromise {
    return this.delete(`${this.USERS_PATH}/block_lk/${userId}`)
  }

  public static getFsspData(props): AxiosPromise {
    return this.post(
      `iidx/check-fssp/${props.userId}/ext?number=${props.number}&force=${props.force}`,
      props.data
    )
  }

  public static getBkiDocuments(props): AxiosPromise {
    return this.get(`images/bki-documents/${props?.userId}`)
  }

  public static getLoanRatingData(props): AxiosPromise {
    return this.post(
      `iidx/loan-rating/${props.userId}?number=${props.number}&force=false`,
      props.data
    )
  }

  public static getLoanRatingResultData({ userId, operationToken }): AxiosPromise {
    return this.get(`iidx/loan-rating/${userId}/${operationToken}`)
  }

  public static getFinScoring(props): AxiosPromise {
    return this.post(
      `iidx/fin-scoring/${props.userId}?number=${props.number}&force=${props.force}`,
      props.data
    )
  }

  public static getFinScoringMfo(props): AxiosPromise {
    return this.post(
      `iidx/fin-scoring-mfo/${props.userId}?number=${props.number}&force=${props.force}`,
      props.data
    )
  }

  public static getPassportData(userId): AxiosPromise {
    return this.get(`${this.USERS_PATH}/passport/${userId}`)
  }

  public static getVerifyPassportData(loanId): AxiosPromise {
    return this.get(`${this.LOAN_PATH}/passport/verify/${loanId}`)
  }

  public static changeVerifyPassportData({ loanId, status }): AxiosPromise {
    return this.post(
      `${this.LOAN_PATH}/passport/verify/${loanId}?status=${status ? 'true' : 'false'}`
    )
  }

  public static getBankCardData(userId): AxiosPromise {
    return this.get(`${this.USERS_PATH}/bank-cards/${userId}`)
  }

  public static deleteBankCardData(cardId): AxiosPromise {
    return this.delete(`${this.USERS_PATH}/bank-cards/${cardId}`)
  }

  public static getPhonesData(userId): AxiosPromise {
    return this.get(`${this.USERS_PATH}/phones/${userId}`)
  }

  public static getAnalyzeData(userId, loanId): AxiosPromise {
    return this.get(`${this.USERS_PATH}/analyze/${userId}?loan_id=${loanId}`)
  }

  public static getLastLoan(userId): AxiosPromise {
    return this.get(`users/last-loan-sum/${userId}`)
  }

  public static getLoanPageById(id: string): AxiosPromise {
    return this.get(`${this.LOAN_PATH}/details/${id}`)
  }

  public static getVideoVerification(customer_id: string): AxiosPromise {
    return this.get(`${this.VIDEO_PATH}/confirmations/${customer_id}`)
  }

  public static deleteVideoVerification(video_id: string): AxiosPromise {
    return this.delete(`${this.VIDEO_PATH}/confirmation/${video_id}`)
  }

  public static getPersonalDataPhoto(id: string): AxiosPromise {
    return this.get(`${this.IMAGES_PATH}/passport/personal_data/${id}`)
  }

  public static getDetailsUserData(id: string): AxiosPromise {
    return this.get(`${this.USERS_PATH}/details/${id}`)
  }

  public static getRegistrationDataPhoto(id: string): AxiosPromise {
    return this.get(`${this.IMAGES_PATH}/passport/registration/${id}`)
  }

  public static getBankCardDataPhoto(id: string): AxiosPromise {
    return this.get(`${this.IMAGES_PATH}/bank_card/${id}`)
  }

  public static getAgentDataPhoto(id: string): AxiosPromise {
    return this.get(`${this.IMAGES_PATH}/agent-photo/${id}`)
  }

  public static getAssignTheLoan(loanId: string): AxiosPromise {
    return this.post(`${this.LOAN_PATH}/assign/${loanId}`)
  }

  public static setPauseLoan(loan_id: string): AxiosPromise {
    return this.post(`${this.LOAN_PATH}/pause/${loan_id}`)
  }

  public static setResumeLoan(loan_id: string): AxiosPromise {
    return this.post(`${this.LOAN_PATH}/resume/${loan_id}`)
  }

  public static getNBKIData(user_id: string): AxiosPromise {
    return this.get(`${this.EXTERNAL_PATH}/nbki/short/${user_id}`)
  }

  public static getPtiData(user_id: string): AxiosPromise {
    return this.get(`${this.EXTERNAL_PATH}/pti/${user_id}`)
  }

  public static getPtiBRSData(loan_number: string): AxiosPromise {
    return this.get(`${this.EXTERNAL_PATH}/pti_brs/${loan_number}`)
  }

  public static getNBKIScoring(user_id: string): AxiosPromise {
    return this.get(`${this.EXTERNAL_PATH}/nbki/scoring/${user_id}`)
  }

  public static getNBKIScoringV2(loan_number: string): AxiosPromise {
    return this.get(`${this.EXTERNAL_PATH}/nbki/scoring/v2/${loan_number}`)
  }

  public static getPayments(loan_id: string): AxiosPromise {
    return this.get(`${this.LOAN_PATH}/schedule/${loan_id}`)
  }

  public static getServices(loanNumber: string): AxiosPromise {
    return this.get(`${this.LOAN_PATH}/extra-services/${loanNumber}`)
  }

  public static getNBKIFullData(data_id: string): AxiosPromise {
    return this.get(`${this.EXTERNAL_PATH}/nbki/full/${data_id}`, {
      responseType: 'blob',
      timeout: 30000,
    })
  }

  public static getIsBanned(id: string): AxiosPromise {
    return this.get(`${this.USERS_PATH}/is-banned/${id}`)
  }

  public static onBan(id: string, options): AxiosPromise {
    const data = JSON.stringify({
      ...options,
    })
    return this.post(`${this.USERS_PATH}/ban/${id}`, data)
  }

  public static onUnban(id: string): AxiosPromise {
    return this.post(`${this.USERS_PATH}/unban/${id}`)
  }

  public static setUnassign(loan_id: string): AxiosPromise {
    return this.post(`${this.LOAN_PATH}/unassign/${loan_id}`)
  }

  public static updateStatusToSettingLimit(loan_id: string): AxiosPromise {
    return this.post(`${this.LOAN_PATH}/update_setting_a_limit/${loan_id}`)
  }

  public static changeStatus(
    id: string,
    timeLimit: number,
    sumLimit: number,
    comment: string,
    status_name: string
  ): AxiosPromise {
    const data = JSON.stringify({
      comment,
      time_limit: timeLimit,
      sum_limit: sumLimit,
      status_name,
    })

    return this.post(`${this.LOAN_PATH}/change_status/${id}`, data)
  }

  public static sendPassportToBlackList({
    full_name,
    passport,
    type,
    comment,
  }: {
    full_name: string
    passport: string
    type: number
    comment: string
  }): AxiosPromise {
    const data = JSON.stringify({
      full_name,
      passport,
      type,
      comment,
    })

    return this.post(`${this.USERS_PATH}/blacklist-kz`, data)
  }

  public static sendSNilsToBlackList({ snils }: { snils: string }): AxiosPromise {
    const data = JSON.stringify({
      snils,
    })

    return this.post(`${this.USERS_PATH}/blacklist-snils/`, data)
  }

  public static changeStatusRevision(
    id: string,
    timeLimit: number,
    sumLimit: number,
    comment: string,
    code: number,
    proposed_other_income?: string
  ): AxiosPromise {
    const data = JSON.stringify({
      time_limit: timeLimit,
      sum_limit: sumLimit,
      comment,
      status_name: 'customer_revision',
      reject_code_id: code,
      ...(proposed_other_income && {
        proposed_other_income,
      }),
    })

    return this.post(`${this.LOAN_PATH}/change_status/${id}`, data)
  }

  public static loanAccept(
    id: string,
    timeLimit: number,
    sumLimit: number,
    comment?: string
  ): AxiosPromise {
    const data = JSON.stringify({
      comment,
      time_limit: timeLimit,
      sum_limit: sumLimit,
    })

    return this.post(`${this.LOAN_PATH}/accept/${id}`, data)
  }

  public static loanAcceptWithoutLimit(id: string, comment?: string): AxiosPromise {
    const data = JSON.stringify({
      comment,
    })

    return this.post(`${this.LOAN_PATH}/accept_without_limit/${id}`, data)
  }

  public static loanRefuse(
    id: string,
    reject_code_id?: number,
    byClient?: boolean,
    comment?: string,
    ban_user?: boolean,
    notice?: boolean
  ): AxiosPromise {
    const data = JSON.stringify({
      comment,
      by_client: byClient,
      ban_user,
      notice,
      ...(reject_code_id !== undefined && { reject_code_id }),
    })

    return this.post(`${this.LOAN_PATH}/refuse/${id}`, data)
  }

  public static getUpdatedINN(user_id: string): AxiosPromise {
    return this.get(`${this.EXTERNAL_PATH}/inn/${user_id}`)
  }

  public static patchINN(user_id: string, inn: string): AxiosPromise {
    return this.patch(`${this.EXTERNAL_PATH}/inn/${user_id}?inn=${inn}`)
  }

  public static getLoanComments(user_id: string): AxiosPromise {
    return this.get(`${this.LOAN_PATH}/loan-comments/${user_id}`)
  }

  public static getLoanMessages(user_id: string): AxiosPromise {
    return this.get(`${this.USERS_PATH}/message-history/${user_id}`)
  }

  public static createLoanComments(userId: string, loanId: string, comment: string): AxiosPromise {
    const data = JSON.stringify({
      user_id: userId,
      loan_id: loanId,
      comment,
    })
    return this.post(`${this.LOAN_PATH}/create-loan-comment/`, data)
  }

  public static updateLoanComments(loanId: string, comment: string): AxiosPromise {
    const data = JSON.stringify({
      text: comment,
    })
    return this.put(`${this.LOAN_PATH}/loan-comments/${loanId}`, data)
  }

  public static deleteImage(image_id: string, user_id: string): AxiosPromise {
    return this.delete(`${this.IMAGES_PATH}/${image_id}?user_id=${user_id}`)
  }

  public static getStatusesHistory(loan_id: string): AxiosPromise {
    return this.get(`${this.LOAN_PATH}/status-history/${loan_id}`)
  }

  public static addImage(
    image: string,
    image_type: string,
    image_extension: string,
    user_id: string
  ): AxiosPromise {
    return this.post(`${this.IMAGES_PATH}/${user_id}`, {
      user_id,
      image,
      image_type,
      image_extension,
    })
  }

  public static updatePersonalData(userId: string, data: IPersonalDataUpdate): AxiosPromise {
    const body = JSON.stringify({
      ...data,
    })
    return this.put(`${this.USERS_PATH}/personal-data/${userId}`, body)
  }

  public static updatePassportData(userId: string, data: IPassportDataUpdate): AxiosPromise {
    const body = JSON.stringify({
      ...data,
    })
    return this.put(`${this.USERS_PATH}/passport/${userId}`, body)
  }

  public static updateRegistrationData(
    userId: string,
    data: IRegistrationDataUpdate
  ): AxiosPromise {
    const body = JSON.stringify({
      ...data,
    })
    return this.put(`${this.USERS_PATH}/passport/registration-address/${userId}`, body)
  }

  public static updateResidenceData(userId: string, data: IResidenceDataUpdate): AxiosPromise {
    const body = JSON.stringify({
      ...data,
    })
    return this.put(`${this.USERS_PATH}/passport/residence-address/${userId}`, body)
  }

  public static updateDetailsData(userId: string, data: IUserDetailsUpdateData): AxiosPromise {
    const body = JSON.stringify({
      ...data,
    })
    return this.put(`${this.USERS_PATH}/details/${userId}`, body)
  }

  public static getUserToken(userId: string): AxiosPromise {
    return this.post(`${this.USERS_PATH}/become/${userId}`)
  }

  public static getLoanTransactions(loan_number: number): AxiosPromise {
    return this.get(`${this.LOAN_PATH}/loan_transactions/${loan_number}`)
  }

  public static getLoanDebt(number: number): AxiosPromise {
    return this.get(`${this.LOAN_PATH}/loan_debt/${number}`)
  }

  public static getAdditionalContacts(userId: string): AxiosPromise {
    return this.get(`${this.USERS_PATH}/customer_familiar/${userId}`)
  }

  public static updateAdditionalContacts(
    familiar_id: string,
    body: IAdditionalContactsPayload
  ): AxiosPromise {
    return this.put(`${this.USERS_PATH}/customer_familiar/${familiar_id}`, body)
  }

  public static createAdditionalContacts(
    userId: string,
    body: IAdditionalContactsPayload[]
  ): AxiosPromise {
    return this.post(`${this.USERS_PATH}/customer_familiar/${userId}`, body)
  }

  public static deleteAdditionalContacts(familiar_id: string): AxiosPromise {
    return this.delete(`${this.USERS_PATH}/customer_familiar/${familiar_id}`)
  }

  public static fetchBankruptData(userId: string): AxiosPromise {
    return this.get(`${this.USERS_PATH}/bankrupt/${userId}`)
  }

  public static fetchLoanGeneralInfoData(loanNumber: string): AxiosPromise {
    return this.get(`${this.LOAN_PATH}/general-info/${loanNumber}`)
  }

  public static updateBankruptData(userId: string, body: IBankruptDataItems): AxiosPromise {
    return this.put(`${this.USERS_PATH}/bankrupt/${userId}`, body)
  }

  public static createBankruptData(userId: string, body: IBankruptDataItems): AxiosPromise {
    return this.post(`${this.USERS_PATH}/bankrupt/${userId}`, body)
  }

  public static deleteBankruptData(userId: string): AxiosPromise {
    return this.delete(`${this.USERS_PATH}/bankrupt/${userId}`)
  }

  public static checkIsBlacklisted(userId: string): AxiosPromise {
    return this.get(`${this.USERS_PATH}/is-blacklisted/${userId}`)
  }

  public static fetchNBKITransferData(customer_id: string): AxiosPromise {
    return this.get(`${this.USERS_PATH}/last_consent/${customer_id}`)
  }
}
