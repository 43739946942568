/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { LoanType } from 'features/loans/types'
import { AgentLoanType } from 'features/agentLoans/types'
import { CollectorType } from 'features/collector/types'
import { StyledTableHeadWrapper, StyledTable, StyledTitle } from './TableHead.styles'

interface ITableHead {
  structure: unknown
  type:
    | keyof typeof AgentLoanType
    | keyof typeof LoanType
    | keyof typeof CollectorType
    | 'all'
    | 'waiting'
    | 'clients'
    | 'lids'
    | number
  template: string
}

export const TableHead = (props: ITableHead): JSX.Element => {
  const { structure, type, template } = props
  return (
    <>
      <StyledTableHeadWrapper template={template}>
        {structure[type]?.map((column) => (
          <StyledTable key={column.id}>
            <StyledTitle> {column.title}</StyledTitle>
          </StyledTable>
        ))}
      </StyledTableHeadWrapper>
    </>
  )
}
