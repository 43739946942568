export const MIN_WIDTH = '1500px'

export const COLUMN_TEMPLATE = {
  0: `
    minmax(200px, 300px) minmax(100px, 180px) minmax(350px, 1fr) minmax(300px, 300px) minmax(200px, 250px)
    `,
  1: `
    minmax(350px, 1fr) minmax(200px, 250px)
    `,
}
