import { v4 as uuid } from 'uuid'

export const STRUCTURE = {
  0: [
    { title: 'ФИО', id: uuid() },
    { title: 'Паспорт', id: uuid() },
    { title: 'Комментарий', id: uuid() },
    { title: 'Дата блокировки', id: uuid() },
    { title: 'Действие', id: uuid() },
  ],
  1: [
    { title: 'СНИЛС', id: uuid() },
    { title: 'Действие', id: uuid() },
  ],
}
