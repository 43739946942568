/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState, useContext } from 'react'
import { uniqBy } from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import StickyBox from 'react-sticky-box'

import { IBanListItem } from 'features/banList/types'
import { LoanType } from 'features/loans/types'

import { GlobalState } from 'index'
import { NavMenuType } from 'App'
import { Nullable } from 'commonTypes'
import { fetchBanListBySearch } from 'features/banList/extra'
import { Controls } from '../../components/Controls'
import { Loader, Search, TableHead } from '../../components'

import {
  StaledContainer,
  StyledEmptyLabel,
  StyledLoaderPage,
  StyledPageWrapper,
  StyledTableWrapper,
} from './SdlList.styles'

import { STRUCTURE } from './Table/tableStructure'
import { COLUMN_TEMPLATE } from './Table/options'
import { Table } from './Table'
import { ITableProps } from './Table/types'
import { NavigationContext } from 'app/contexts'

export const BanList = (): JSX.Element => {
  const dispatch = useDispatch()
  const { data: filters } = useSelector((store: GlobalState) => store.banListFilters)
  const { listing, isFetching } = useSelector((store: GlobalState) => store.banList)

  const [showSearch, setShowSearch] = useState<boolean>(true)
  const [filteredListing, setFilteredListing] = useState<Nullable<IBanListItem[]>>(null)
  const { setNavigation, setNavMenuType } = useContext(NavigationContext)

  useEffect(() => {
    setNavMenuType(NavMenuType.underwriter)
  }, [])

  useEffect(() => {
    if (listing?.data) setFilteredListing(uniqBy(listing.data, 'loans_user_id'))
    else setFilteredListing([])
  }, [listing])

  useEffect(() => {
    setNavigation({
      activeMenu: null,
      headContent: `Андеррайтер`,
      backTo: false,
    })
  }, [])

  useEffect(() => {
    if (filters?.search) dispatch(fetchBanListBySearch({ search: filters.search }))
  }, [filters])

  const toggleSearch = (): void => {
    setShowSearch(!showSearch)
  }

  const setShowFilters = () => null

  const filterProps = {
    toggleSearch,
    showSearch,
    setShowSearch,
    filtersVisible: false,
    setShowFilters,
    pageControlsStructure: 'banList',
    actionType: 'banList',
  }

  return (
    <StyledPageWrapper>
      {isFetching && (
        <StyledLoaderPage>
          <Loader />
        </StyledLoaderPage>
      )}
      <Controls {...filterProps} />
      <Search {...filterProps} pending={false} />
      <div style={{ overflow: 'auto' }} id='scrollable'>
        <StickyBox>
          <TableHead structure={STRUCTURE} template={COLUMN_TEMPLATE.all} type='all' />
        </StickyBox>
        <StyledTableWrapper>
          <>
            {filteredListing ? (
              filteredListing?.map((banList: IBanListItem, index) => {
                const TableProps: ITableProps = {
                  adminUsername: banList.admin_username,
                  firstName: banList.first_name,
                  lastName: banList.last_name,
                  middleName: banList.middle_name,
                  comment: banList.comment,
                  unlockDate: banList.unlock_date,
                  lockDate: banList.lock_date,
                  userId: banList.user_id,
                  isBanned: banList.is_banned,
                  isPermanent: banList.is_permanent,
                  type: LoanType.all,
                }
                return (
                  <StaledContainer key={index}>
                    <Table {...TableProps} />
                  </StaledContainer>
                )
              })
            ) : (
              <StyledEmptyLabel>{!isFetching && <p>Ничего не найдено</p>}</StyledEmptyLabel>
            )}
          </>
        </StyledTableWrapper>
      </div>
    </StyledPageWrapper>
  )
}
