/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable indent */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  BlackListByPassport,
  BlackListBySnils,
  ProjectBlacklistService,
} from 'services/blackListervice'

interface WithCallbacks {
  onSuccess?: () => void
  onError?: () => void
}
interface BlackListByPassportThunk extends BlackListByPassport, WithCallbacks {}

interface BlackListBySnilsThunk extends BlackListBySnils, WithCallbacks {}

export const getBlackListByPassport = createAsyncThunk<
  any,
  { search: string; onError?: () => void; onSuccess?: () => void }
>('blackList/getBlackListByPassport', async (props) => {
  const { search, onError, onSuccess } = props
  const response = await ProjectBlacklistService.getBlackListByPassport({ search })
    .then(({ data }) => {
      onSuccess && onSuccess()
      return data
    })
    .catch((e) => {
      onError && onError()
      throw new Error(e)
    })
  return response
})

export const getBlackListBySnils = createAsyncThunk<
  any,
  { search: string; onError?: () => void; onSuccess?: () => void }
>('blackList/getBlackListBySnils', async (props) => {
  const { search, onError, onSuccess } = props
  const response = await ProjectBlacklistService.getBlackListBySnils({ search })
    .then(({ data }) => {
      onSuccess && onSuccess()
      return data
    })
    .catch((e) => {
      onError && onError()
      throw new Error(e)
    })
  return response
})

export const removeFromBlackList = createAsyncThunk<
  any,
  { passport?: string; snils?: string; onSuccess?: () => void }
>('blackList/removeFromBlackList', async (props) => {
  const { passport, snils, onSuccess } = props
  const response = await ProjectBlacklistService.removeFromBlackList({
    passport,
    snils,
  }).then(({ data }) => {
    onSuccess && onSuccess()
    return data
  })
  return response
})

export const addToBlackListByPassport = createAsyncThunk<undefined, BlackListByPassportThunk>(
  'blackList/addToBlackListByPassport',
  async (props) => {
    const { full_name, passport, type, comment, onSuccess, onError } = props
    const response = await ProjectBlacklistService.addToBlackListByPassport({
      full_name,
      passport,
      type,
      comment,
    })
      .then(({ data }) => {
        onSuccess && onSuccess()
        return data
      })
      .catch((e) => {
        onError && onError()
        throw new Error(e)
      })

    return response
  }
)

export const addToBlackListBySnils = createAsyncThunk<undefined, BlackListBySnilsThunk>(
  'blackList/addToBlackListBySnils',
  async (props) => {
    const { snils, onSuccess, onError } = props
    const response = await ProjectBlacklistService.addToBlackListBySnils({
      snils,
    })
      .then(({ data }) => {
        onSuccess && onSuccess()
        return data
      })
      .catch((e) => {
        onError && onError()
        throw new Error(e)
      })
    return response
  }
)
