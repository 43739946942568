/* eslint-disable react/require-default-props */
import { StyledLoaderWrapper, StyledLoaderRing } from './SmallLoader.styles'

interface ISmallLoader {
  button?: boolean
  extraSmall?: boolean
}
export const SmallLoader = ({ button, extraSmall = false }: ISmallLoader): JSX.Element => (
  <StyledLoaderWrapper button={button} extraSmall={extraSmall}>
    <StyledLoaderRing>
      <div />
      <div />
      <div />
      <div />
    </StyledLoaderRing>
  </StyledLoaderWrapper>
)
