import styled from 'styled-components/macro'
import { THEME } from 'core/theme/theme'

export const StyledUserInfo = styled.div`
  width: 100%;
  padding: 15px 22px;
  display: flex;
  flex-direction: column;
  position: relative;
  border-bottom: 1px solid ${THEME.COLORS.BORDER_LOAN};
  border-right: 1px solid ${THEME.COLORS.BORDER_LOAN};
`

export const StyledTitle = styled.p`
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #828282;
  display: flex;
`

export const StyledValue = styled.p`
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  color: ${THEME.COLORS.DARK_BLUE};
  white-space: break-spaces;
`
