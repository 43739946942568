/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/naming-convention,@typescript-eslint/no-unused-vars */
/* eslint-disable indent */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  addToBlackListByPassport,
  addToBlackListBySnils,
  getBlackListByPassport,
  getBlackListBySnils,
  removeFromBlackList,
} from './extra'
import { BlackListByPassportList, BlackListSlice } from './types'

const initialState: BlackListSlice = {
  loadable: {},
  blackListByPassportList: [],
  blackListBySnilsList: [],
}

export const blackListLoansSlice = createSlice({
  name: 'blacklist',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBlackListByPassport.pending, (state) => {
        state.loadable['blackListByPassportList'] = true
      })
      .addCase(getBlackListByPassport.rejected, (state) => {
        state.loadable['blackListByPassportList'] = false
      })
      .addCase(
        getBlackListByPassport.fulfilled,
        (state, { payload }: PayloadAction<BlackListByPassportList[]>) => {
          state.loadable['blackListByPassportList'] = false
          state.blackListByPassportList = payload
        }
      )
    builder
      .addCase(getBlackListBySnils.pending, (state) => {
        state.loadable['blackListBySnilsList'] = true
      })
      .addCase(getBlackListBySnils.rejected, (state) => {
        state.loadable['blackListBySnilsList'] = false
      })
      .addCase(
        getBlackListBySnils.fulfilled,
        (state, { payload }: PayloadAction<{ snils: string }[]>) => {
          state.loadable['blackListBySnilsList'] = false
          state.blackListBySnilsList = payload
        }
      )
    builder
      .addCase(removeFromBlackList.pending, (state) => {
        state.loadable['removeFromBlackList'] = true
      })
      .addCase(removeFromBlackList.rejected, (state) => {
        state.loadable['removeFromBlackList'] = false
      })
      .addCase(removeFromBlackList.fulfilled, (state) => {
        state.loadable['removeFromBlackList'] = false
      })
    builder
      .addCase(addToBlackListByPassport.pending, (state) => {
        state.loadable['addToBlackListByPassport'] = true
      })
      .addCase(addToBlackListByPassport.rejected, (state) => {
        state.loadable['addToBlackListByPassport'] = false
      })
      .addCase(addToBlackListByPassport.fulfilled, (state) => {
        state.loadable['addToBlackListByPassport'] = false
      })
    builder
      .addCase(addToBlackListBySnils.pending, (state) => {
        state.loadable['addToBlackListBySnils'] = true
      })
      .addCase(addToBlackListBySnils.rejected, (state) => {
        state.loadable['addToBlackListBySnils'] = false
      })
      .addCase(addToBlackListBySnils.fulfilled, (state) => {
        state.loadable['addToBlackListBySnils'] = false
      })
  },
})

export default blackListLoansSlice.reducer
