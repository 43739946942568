/* eslint-disable import/order */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, useContext, useRef } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, batch, useSelector } from 'react-redux'
import StickyBox from 'react-sticky-box'

import { GlobalState } from 'index'
import { NavMenuType } from 'App'
import { ToastTypes } from 'lib/toast/Toast'
import {
  Button,
  Loader,
  ModalConfirm,
  UserInformation,
  Navigation,
  ScrollToTop,
  Select,
} from 'components'
import { INavigationItem } from 'components/Navigation/Navigation'

import {
  fetchAgentDataPhoto,
  fetchAgentLoanUserPassportData,
  fetchAgentLoanUserPersonalData,
  fetchLoanPageById,
  fetchPersonalAgentsDataPhoto,
  fetchPhonesData,
  fetchRegistrationAgentsDataPhoto,
  fetchUserLoans,
  getLoanComments,
  getAgentUsers,
  refuseLoan,
  changeAgent,
  fetchAgentCheckIsBlacklisted,
} from 'features/agentLoans/extra'

import {
  fetchAdditionalContacts,
  fetchBankCardDataPhoto,
  fetchBankruptData,
  fetchNBKIScoringData,
  fetchPassportData,
  fetchPersonalDataPhoto,
  fetchRegistrationDataPhoto,
  fetchServicesData,
  fetchUserDetailsData,
  fetchVideoVerification,
  getStatusesHistory,
} from 'features/loan/extra'

import {
  StyledLoaderPage,
  StyledBlockNav,
  StyledWrapper,
  StyledContent,
  StyledNavContent,
} from './AgentLoan.styles'

import { UNDERWRITER_LOANS_ROUTE, AGENT_LOANS_ROUTE } from 'constants/pagesRoutes'
import { addToast } from 'features/toast/toastSlice'
import { resetAgentsLoans } from 'features/agentLoans/loanSlice'
import { useGetLoanData, useGetLoanPassportData } from 'features/loan/loanSelectors'

import { rolesType } from 'constants/rtoles'
import { HeadInfo, Head } from './Head'
import { Passport, Agreement, Contacts, Comments, SetLimit } from './Modules'

import {
  AdditionalContacts,
  PersonalData,
  Registration,
  Residence,
  UserDetails,
  StatusesHistory,
  BankCards,
  Services,
  VideoConfirmation,
  Fssp,
  BankruptData,
  LoansHistory,
  Documents,
} from 'pages/UnderwriterLoan/Modules'

import { LoanStatuses, checkLoanIssuedDate, checkLoanIssuedStatus, getAdminFio } from 'helpers'
import { useGetAgentUserNamesWithIds } from 'features/dict/dictSelectors'
import { StyledSelectAgent } from 'pages/AgentList/AgentPage.styles'
import { NavigationContext } from 'app/contexts'
import { PDNLimitCalculate } from 'pages/UnderwriterLoan/Modules/SetLimitInstallment/PDNLimitCalculate'
import { getPZDocumentsList, getSaasDocumentsList } from 'features/commonDocuments/extra'

const navigationList = (): INavigationItem[] => {
  const list = [
    { title: 'Персональные данные', anchor: 'personal' },
    { title: 'Паспортные данные', anchor: 'passport' },
    { title: 'Банкротство', anchor: 'bankrupt' },
    { title: 'Видео идентификация', anchor: 'videoConfirmation' },
    { title: 'Адрес регистрации', anchor: 'address' },
    { title: 'Адрес проживания', anchor: 'residence' },
    { title: 'Дополнительные контакты', anchor: 'additionalContacts' },
    { title: 'Документы', anchor: 'documents' },
    { title: 'Занятость и доходы', anchor: 'income' },
    { title: 'Контакты', anchor: 'contacts' },
    { title: 'История статусов', anchor: 'statusesHistory' },
    { title: 'Банковские карты', anchor: 'cards' },
    { title: 'Анкета и согласие', anchor: 'form' },
    { title: 'История займов', anchor: 'loansHistory' },
    { title: 'Комментарии андеррайтера ', anchor: 'agentComments' },
    { title: 'Услуги', anchor: 'services' },
    { title: 'ФССП', anchor: 'fssp' },
  ]
  return list
}

const canAgentRefuseLoan = (currentLoanStatus: string, isBelongsToAgent: boolean): boolean => {
  const refusalsStatuses = [
    LoanStatuses.on_check,
    LoanStatuses.setting_a_limit,
    LoanStatuses.loan_approved,
  ]
  const isStatusReusable = refusalsStatuses.includes(currentLoanStatus)
  return isStatusReusable && isBelongsToAgent
}

interface IShowModal {
  title: string
  description: string
  show: boolean
}

interface AgentLoanProps {
  claims: string
}

const initialModalState = { title: '', description: '', action: null, show: false }

export const AgentLoan: React.FC<AgentLoanProps> = ({ claims }) => {
  const dictData = useSelector((state: GlobalState) => state.dict)
  const history = useHistory()
  const dispatch = useDispatch()
  const { id } = useParams<{ id: string | undefined }>()
  const [openedModules, setOpenedModules] = useState<string[]>([])
  const [loanCanBeRefuse, setLoanCanBeRefuse] = useState<boolean>(false)
  const [isAgentCanEdit, setIsAgentCanEdit] = useState<boolean>(false)
  const [customerPhone, setCustomerPhone] = useState<string>('')
  const [assignAgent, setAssignAgent] = useState<string>('')
  const agentUserNamesWithIds = useGetAgentUserNamesWithIds() || []
  const AgentUsernamesList = agentUserNamesWithIds.map((value) => ({
    label: getAdminFio(value.username, dictData),
    value: value.username,
  }))

  const [showModal, setShowModal] = useState<IShowModal>(initialModalState)
  const { userData, loanData, currentLoanIsBelongsToAgent } = useSelector(
    (store: GlobalState) => store.agentLoans
  )
  const { loan_status: loanStatus } = useGetLoanData()
  const { setNavigation, isAgentRole, setNavMenuType } = useContext(NavigationContext)

  const personalData = useSelector((store: GlobalState) => store.agentLoans.personalData)
  const { loadData, statusesHistory } = useSelector((store: GlobalState) => store.singleLoan)
  const isLoading: any = useRef(null)
  isLoading.current = undefined

  useEffect(() => {
    if (isAgentRole === false) {
      history.push(UNDERWRITER_LOANS_ROUTE)
      setNavMenuType(NavMenuType.underwriter)
    } else setNavMenuType(NavMenuType.agent)
  }, [isAgentRole])

  useEffect(() => {
    if (
      currentLoanIsBelongsToAgent &&
      [LoanStatuses.on_check, LoanStatuses.requesting_loan, LoanStatuses.agent_in_work].includes(
        loanData?.loan_status
      )
    )
      setIsAgentCanEdit(true)
    else setIsAgentCanEdit(false)
  }, [currentLoanIsBelongsToAgent, loanData?.loan_status])

  useEffect(() => {
    if (canAgentRefuseLoan(loanData?.loan_status, currentLoanIsBelongsToAgent))
      setLoanCanBeRefuse(true)
    else setLoanCanBeRefuse(false)
  }, [currentLoanIsBelongsToAgent, loanData])

  useEffect(() => {
    if (isLoading.current === undefined) {
      isLoading.current = true
      dispatch(fetchLoanPageById({ id })) // 3
    }
    return () => {
      dispatch(resetAgentsLoans(false))
    }
  }, [])

  const {
    first_name: firstName = '',
    last_name: lastName = '',
    middle_name: middleName = '',
    date_of_birth: birthDate,
    serial,
    number,
    date_of_issue: dateOfIssue,
  } = useGetLoanPassportData()

  const fetchVideoData = () => {
    if (userData?.customer_id)
      dispatch(fetchVideoVerification({ customer_id: userData?.customer_id }))
  }

  const fetchUserData = () => {
    if (userData?.user_id) {
      batch(() => {
        dispatch(fetchAdditionalContacts({ id: userData.user_id }))
        dispatch(fetchPersonalDataPhoto({ id: userData.user_id })) // 1
        dispatch(fetchRegistrationDataPhoto({ id: userData.user_id })) // 2
        dispatch(fetchBankCardDataPhoto({ id: userData.user_id }))
        dispatch(fetchAgentLoanUserPassportData({ userId: userData.user_id })) // 4
        dispatch(fetchAgentLoanUserPersonalData({ userId: userData.user_id }))
        dispatch(fetchPersonalAgentsDataPhoto({ id: userData.user_id })) // 1
        dispatch(fetchAgentDataPhoto({ id: userData.user_id }))
        dispatch(fetchRegistrationAgentsDataPhoto({ id: userData.user_id })) // 2
        dispatch(fetchPhonesData({ id: userData.user_id }))
        dispatch(fetchUserLoans({ ids: [`${userData.user_id}`] }))
        dispatch(getLoanComments({ loanNumber: loanData?.loan_number }))
        dispatch(getAgentUsers({ id: userData.user_id }))
        dispatch(getStatusesHistory({ id: loanData?.loan_id }))
        dispatch(fetchUserDetailsData({ id: userData.user_id }))
        dispatch(fetchServicesData({ loanNumber: loanData?.loan_number }))
        dispatch(fetchBankruptData({ userId: userData?.user_id }))
        dispatch(fetchAgentCheckIsBlacklisted({ userId: userData?.user_id }))
        dispatch(fetchNBKIScoringData({ id: loanData?.loan_number }))
        dispatch(getSaasDocumentsList({ loan_number: loadData?.loan_number }))
        dispatch(getPZDocumentsList({ loan_id: loadData?.questionnaire_id }))
      })
    }
  }

  useEffect(() => {
    if (userData?.user_id) dispatch(fetchPassportData({ id: userData.user_id })) // 4
  }, [userData?.user_id])

  useEffect(() => {
    if (userData?.user_id && firstName) fetchUserData()
  }, [userData?.user_id, firstName])

  useEffect(() => {
    fetchVideoData()
  }, [userData?.customer_id])

  // useEffect(() => {
  //   fetchUserData()
  //   fetchVideoData()
  // }, [firstName, lastName])

  const onToggleModule = (anchor: string): void => {
    const newOpenedModules = [...openedModules]
    const index = newOpenedModules.indexOf(anchor)
    index !== -1 ? newOpenedModules.splice(index, 1) : newOpenedModules.push(anchor)
    setOpenedModules(newOpenedModules)
  }

  const onNavigationClick = (anchor: string): void => {
    if (!openedModules.includes(anchor)) setOpenedModules([...openedModules, anchor])
  }

  const isEditable = true

  const showPDNLimits =
    checkLoanIssuedDate(loadData?.loan_date_create) &&
    (checkLoanIssuedStatus(statusesHistory?.data) ||
      [
        LoanStatuses.setting_a_limit,
        LoanStatuses.on_check,
        LoanStatuses.agent_in_work,
        LoanStatuses.customer_revision,
      ].includes(loanStatus))

  const props = {
    openedModules,
    onToggleModule,
    loan_id: id,
    loanNumber: loanData?.loan_number,
    creditProduct: loanData?.credit_product,
    loanStatus: loanData?.loan_status,
    claimsResolve: isAgentCanEdit,
    role: rolesType.agent,
    userId: userData?.user_id,
    customerId: userData?.customer_id,
    currentLoanIsBelongsToAgent,
    isEditable,
    claims,
  }

  const onRollUp = (): void => {
    setOpenedModules([])
    const el = document.getElementById('loan-content')
    if (el)
      el.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
  }
  const onRollDown = (): void => {
    const anchors = navigationList().map((v) => v.anchor)
    setOpenedModules(anchors)
  }

  const isFetching = false

  useEffect(() => {
    if (loanData) {
      setNavigation({
        activeMenu: null,
        headContent: `Агент Заявка №${loanData?.loan_number}`,
        backTo: '/agent/loans?type=all_app',
        slug: 'agent',
      })
    }
  }, [loanData])

  const handleRefuseLoan = (comment: string): void => {
    dispatch(
      refuseLoan({
        id,
        comment,
        byClient: false,
        notice: true,
        reject_code_id: 0,
        onSuccess: () => {
          history.push(AGENT_LOANS_ROUTE)
        },
        onError: () =>
          dispatch(
            addToast({
              type: ToastTypes.warning,
              title: 'Ошибка',
              description: `Во время выполнения запроса произошла ошибка, статус не обновлен`,
            })
          ),
      })
    )
  }

  const fetchAgentChange = () => {
    if (!assignAgent) return
    dispatch(
      changeAgent({
        phone: customerPhone,
        agent_id: agentUserNamesWithIds.find((x) => x.username === assignAgent)?.admin_id || '',
        errorAction: () => {
          setShowModal(initialModalState)
          dispatch(
            addToast({
              type: ToastTypes.warning,
              title: 'Ошибка',
              description: `Открепление произошло с ошибкой`,
            })
          )
        },
        successAction: () => {
          setShowModal(initialModalState)
          dispatch(
            addToast({
              type: ToastTypes.success,
              title: 'Успех',
              description: `Передача клиента прошла успешно`,
            })
          )
        },
      })
    )
  }
  const handleChangeAgentModal = (phone) => (e) => {
    e.preventDefault()
    e.stopPropagation()
    setCustomerPhone(phone)
    setShowModal({
      show: true,
      title: 'Перезакрепление клиента.',
      description: 'Выберите агента для перезакрепления',
    })
  }

  return (
    <>
      {isFetching ? (
        <StyledLoaderPage>
          <Loader />
        </StyledLoaderPage>
      ) : (
        <>
          <ScrollToTop />
          <StyledWrapper>
            <StyledBlockNav>
              <UserInformation
                fullName={[userData?.last_name, userData?.first_name, userData?.middle_name].join(
                  ' '
                )}
                creditProduct={loadData?.credit_product}
              />
              <Navigation
                list={navigationList()}
                containerId='loan-content'
                onChange={onNavigationClick}
              />
            </StyledBlockNav>
            <StyledNavContent>
              <StickyBox>
                <HeadInfo
                  onRollUp={onRollUp}
                  onRollDown={onRollDown}
                  handleRefuseLoan={handleRefuseLoan}
                  loanCanBeRefuse={loanCanBeRefuse}
                  isAssignLoan={
                    currentLoanIsBelongsToAgent && loanData?.loan_status === LoanStatuses.on_check
                  }
                  onChangeAgent={handleChangeAgentModal(personalData?.login)}
                />
              </StickyBox>
              <StyledContent id='loan-content'>
                <Head />
                <PersonalData {...props} claimsResolve={false} />
                <Passport {...props} />
                <BankruptData {...props} />
                <VideoConfirmation {...props} />
                <Registration {...props} />
                <Residence {...props} />
                <AdditionalContacts {...props} />
                <Documents {...props} />
                <UserDetails {...props} />
                <Contacts {...props} />
                <StatusesHistory {...props} />
                <BankCards {...props} />
                <Agreement {...props} />
                <LoansHistory {...props} />
                <Comments {...props} />
                <Services {...props} />
                <Fssp {...props} />
                {showPDNLimits && <PDNLimitCalculate showRecalculate />}
                {loanData?.loan_status === LoanStatuses.agent_in_work &&
                  currentLoanIsBelongsToAgent && <SetLimit {...props} />}
              </StyledContent>
            </StyledNavContent>
          </StyledWrapper>
        </>
      )}
      <ModalConfirm
        isOpen={showModal.show}
        onRequestClose={() => setShowModal({ ...showModal, show: false })}
        onClose={() => setShowModal({ ...showModal, show: false })}
        description={showModal.description}
        title={showModal.title}
        buttons={false}
        content={() => (
          <div>
            <StyledSelectAgent>
              <Select
                data={AgentUsernamesList}
                placeholder='Агент'
                value={AgentUsernamesList?.find((v) => v.value === assignAgent) || ''}
                onChange={({ value }) => setAssignAgent(value)}
              />
            </StyledSelectAgent>

            <Button type='standardBig' onClick={fetchAgentChange} disabled={!assignAgent}>
              Перезакрепить
            </Button>
            <Button type='emptyBig' onClick={() => setShowModal(initialModalState)}>
              Отмена
            </Button>
          </div>
        )}
      />
    </>
  )
}
