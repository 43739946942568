import { Nullable } from 'commonTypes'

export interface IBankCardsData {
  card_number: string
  card_holder: string
  card_expiration_date: string
  card_added_at: string
}

export interface IUserData {
  user_id: string
  first_name: string
  last_name: string
  middle_name: string
  customer_id: Nullable<number>
}

export interface IUpdateINN {
  isFetching: boolean
}

export enum LoanStatus {
  setting_a_limit = 'setting_a_limit',
}

export interface IScoring {
  error?: Nullable<string>
  verdict: Nullable<string>
  score_points: Nullable<number>
  verdict_thresholds: {
    grayzone: Nullable<number>
    approve: Nullable<number>
  }
}

export interface IPtiData {
  data: Pti[]
  fetching: boolean
  error: boolean
}
export interface IPtiBRSData {
  data: PtiBRS[] | []
  fetching: boolean
  error: boolean
}

export interface Pti {
  loan_number: string
  pti: number
  pti_settlement_date: string
}

export interface PtiBRS {
  loan_number: string
  pdn: number
  calc_date: string
}

export interface IAdditionalContactsData {
  data: IAdditionalContacts[]
  isFetching: boolean
  isUpdating: boolean
  error: boolean
}

export interface IAdditionalContacts {
  id: string
  first_name: string
  last_name: string
  middle_name: string
  familiar_type: string
  comment: string
  number_phone: string
}

export interface INBKITransferData {
  is_bki_change_approve: boolean
  last_change_date: string
}

export interface INBKITransfer {
  data: INBKITransferData
  isFetching: boolean
}

export interface ILoanSlice {
  additionalContacts: IAdditionalContactsData
  scoring: IScoring
  selectedLoanData: Nullable<ILoanPageData>
  pending: boolean
  resolve: any
  isFetchingLoanPage: boolean
  isCompleteLoanPage: boolean
  isOverdue: any
  fetchingLoanPageError: boolean
  loadData: ILoanData
  userData: IUserData
  passportData: IPassportData
  bankruptData: IBankruptData
  loanGeneralInfoData: ILoanGeneralInfoData
  personalData: IPersonalData
  otherDocs: IOtherDocs
  bankCardsData: IBankCardsData[]
  bankCardsFetch: boolean
  userDetailsData: IUserDetails
  registrationData: IRegistrationData
  analyzeData: IAnalyzeData
  residenceData: IResidenceData
  expandCounterLoanItems: number
  loanImageData: IImageLoanData
  loanImageDataFetch: IImageLoanDataFetch
  nbkiHistoryData: INbkiHitory[] | []
  nbkiScoringData: INbkiScoring
  ptiData: IPtiData
  ptiBRSData: IPtiBRSData
  services: []
  servicesPending: boolean
  isBanned: any
  userId: string | null
  assignInfo: IAssignInfo
  statusesLoan: IStatusLoan
  autoCheck: IAutoCheck
  fssp: any
  loanRating: any
  finScoringMfo: any
  same_address: null | boolean
  phonesData: IPhones
  updateINN: IUpdateINN
  commentsData: IComments
  messagesData: IComments
  statusesHistory: IStatusHistory
  payments: any
  scoringHistory: any
  dataTransactions: ILoadTransactionsData[]
  dataDept: ILoadDebtData[]
  videoVerification: IVideoVerification
  in_passport: string
  femida: any
  bkiDocuments: any
  verifyPassportData: boolean
  blacklisted: boolean
  nbkiTransferData: INBKITransfer
}
export interface ILoadTransactionsData {
  loan_id: string
  amount: number
  trans_type: string
  trans_date: string
}
export interface ILoadDebtData {
  loan_id: string
  total_debt: number
  principal_debt: number
  interest_debt: number
  penny_debt: number
  frozen_interest_debt: number
  added_at: string
}
export interface IOtherDocs {
  snils_number: string
  inn_number: string
}

export interface IStatusLoan {
  // same_address?: boolean
  is_free: boolean
  is_in_work: boolean
  is_paused: boolean
}

export interface IAutoCheck {
  isFetching: boolean
  autoCheckData: IAutoCheckData | null
}

export interface IAutoCheckData {
  is_banned: boolean
  is_central_bank_passed: boolean
  is_rosfinmo_passed: boolean
  is_form_data_valid: boolean
  is_passport_valid: boolean
  is_region_banned: boolean
  is_employment_banned: boolean
  is_passport_fsm_passed: boolean
  is_debt_limit_exceeded: boolean
  is_phone_verified: boolean
  is_inn_fns_passed: boolean
  is_uprid_passed: boolean
  is_nbki_actual: boolean
  is_nbki_passed: boolean
  final_verdict: boolean
  fin_card: boolean
  pre_approval: boolean
  score: string
  permitted_loan_sum: string
}

export interface ILoanPageData {
  loan_data: ILoanData
  user_data: IUserData
  assign_info: any
  is_free: boolean
  is_in_work: boolean
  is_paused: boolean
  is_overdue: any
  agent_username: any
  loan_balance?: string
}

export interface IPersonalData {
  isFetching: boolean
  isUpdating: boolean
  login: string
  date_register: string
  time_zone: string
  check_word: string
  maiden_name: string
  marital_status: string
  children_amount: string
  email: string
  additional_services: string
  criminal_records: string
  user_id: string
}

export interface IPersonalDataUpdate {
  time_zone?: string
  email: string
  marital_status?: string
}
export interface IPhones {
  isFetching: boolean
  dataPhones: IPhonesData[]
}

export interface IVideoVerificationData {
  customer_id: number
  code: string
  time_spent: number
  create_date: string
  video_url: string
  video_id: string
}

export interface IVideoVerification {
  isFetching: boolean
  data: IVideoVerificationData[] | []
}

export interface IPhonesData {
  number: string
  number_type: string
  is_default: boolean
  is_active: boolean
  comment: string
}

export interface IRegistrationData {
  isFetching?: boolean
  isUpdating?: boolean
  residence_date: string
  residence_place: string
  postal_code: string
  region: string
  area: string
  district: string
  city: string
  street: string
  building: string
  house_number: string
  block: string
  apartment: string
  other: string
}
export interface IRegistrationDataUpdate {
  postal_code: string
  region: string
  district?: string
  city: string
  street: string
  building?: string
  house_number: string
  block?: string
  apartment?: string
  other?: string
  in_passport?: string
}

export type IResidenceData = IRegistrationData

export type IResidenceDataUpdate = IRegistrationDataUpdate

export interface ILoanData {
  loan_content_id: string
  loan_id: string
  loan_number: string
  loan_source: unknown
  loan_date_create: string
  loan_issue_date: string
  loan_status: string
  loan_ask_sum: number
  loan_ask_period: number
  loan_permitted_sum: string
  loan_permitted_period: string
  loan_settings: unknown
  loan_repeated: boolean
  requests_count: number
  product_percent_day: number
  credit_product: string
  is_third_transfer_approved: boolean
  is_transfer_bki_approved: boolean
  questionnaire_id: string
}

export interface IPassportData {
  isFetching: boolean
  isUpdating: boolean
  first_name: string
  last_name: string
  middle_name: string
  gender: string
  date_of_birth: string
  place_of_birth: string
  citizenship: ''
  serial: string
  number: string
  date_of_issue: string
  place_of_issue: string
  issued_department_url: string
  code_division: string
  registration_address: IRegistrationData | null
  residence_address: IResidenceData | null
  region?: string
  previous_passport_data?: string
}

export interface ILoanGeneralInfoData {
  data: ILoanGeneralInfoDataItem
  isFetching: boolean
}

export interface ILoanGeneralInfoDataItem {
  weekly_payment_sum: number
  last_deposit: number
  last_actual_payment_date: string
  left_to_pay_sum: number
  last_overdue_days: number
  total_overdue_days: number
  has_current_early_repayment: boolean
  early_repayment_date: string
  early_repayment_amount: number
  shown_total_amoun: number
}

export interface IBankruptData {
  data: IBankruptDataItems
  isFetching: boolean
  isUpdating: boolean
}

export interface IBankruptDataItems {
  id: number
  user_id: number
  statement_date: string
  bankrupt_date: string
  is_all_debt_off: boolean
  is_deliberate_bankrupt: boolean
  is_fraud_credit: boolean
  is_bankrupt_credit: boolean
  bankrupt_company_name: string
  bankrupt_credit_decision: string
}

export type IPassportDataUpdate = Omit<
  IPassportData,
  | 'citizenship'
  | 'issued_department_url'
  | 'isUpdating'
  | 'isFetching'
  | 'registration_address'
  | 'residence_address'
>

export interface IUserDetails {
  isFetching: boolean
  isUpdating: boolean
  data: IUserDetailsData
}

export interface IUserDetailsData {
  education_level: string
  activity_type: string
  employment_type: string
  work_position: string
  company_name: string
  company_address: string
  company_employees_amount: string
  company_chief_name: string
  monthly_income: number
  monthly_outcome: string
  other_income: string
  next_salary_date: string
  average_annual_income: string
  work_experience: string
  last_work_experience: string
  has_a_car: boolean
  monthly_credit_obligations: number
  is_banned_cabinet: boolean
}

export interface IUserDetailsUpdateData {
  education_level: string
  activity_type?: string
  employment_type: string
  monthly_income: string
  monthly_outcome?: string
  other_income?: string
  next_salary_date?: string
  average_annual_income?: number
  company_name?: string
  company_address?: string
  work_position?: string
  company_employees_amount?: string
  company_chief_name?: string
  work_experience?: number
  last_work_experience?: number
  has_a_car?: string
  monthly_credit_obligations?: string
}

export interface IAnalyzeData {
  isFetching: boolean
  intersection: IIntersectionData
  ip: IIPData
}

export interface IIntersectionData {
  phones: string[]
  registration_addresses: string[]
  residence_addresses: string[]
  bank_cards: string[]
  passports: string[]
  cookies: string
}

export interface IIPData {
  loan_ip: string
  region: string
  first_login_date: string
  register_place: string[]
  loans_from_ip: string[]
  approved_loans_from_ip: string[]
}

export interface IPhoto {
  image_id: string
  user_id: string
  created_at: string
  image_type_id: number
  bucket_name: string
  object_name: string
  photo_url: string
}

export interface INbkiHitory {
  request_number: string
  loan_number: number
  requested_at: string
  username: string
  pti: number
  pti_settlement_date: Date
}

export interface INbkiScoring {
  score: number
}

export interface IImageLoanData {
  personalDataPhoto: IPhoto[]
  registrationDataPhoto: IPhoto[]
  bankCardDataPhoto: IPhoto[]
  agentDataPhoto: IPhoto[]
}

export interface IImageLoanDataFetch {
  bankCardDataPhotoFetching: boolean
  registrationDataPhotoFetching: boolean
  personalDataPhotoFetching: boolean
  agentDataPhotoFetching: boolean
}

export interface IAssignInfo {
  admin_id: string[]
  total_time: number
  start_date: string
  stop_date: string
  is_paused: boolean
}

export interface IComments {
  isFetching: boolean
  isUpdating: boolean
  pending: boolean
  data: ICommentsData[]
}

export interface ICommentsData {
  username: string
  comment: string
  added_at: string
}

export interface IStatusHistory {
  isFetching: boolean
  data: IStatusHistoryData[]
}
export interface IStatusHistoryData {
  status_id: string
  status_name: string
  status_date: string
  username: string
  comment: string
}

export interface IAdditionalContactsPayload {
  number_phone: string
  first_name: string
  middle_name: string
  familiar_type: string
  comment?: string
}
