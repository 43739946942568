/* eslint-disable indent */
/* eslint-disable arrow-body-style */
/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable import/order */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-array-index-key */
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Module, SmallLoader } from 'components'
import { GlobalState } from 'index'
import { CommonDocumentsService } from 'services/commonDocumentService'
import { normalizeValues } from 'helpers'

import {
  StyledBKIDocumentsWrapper,
  StyledDocsDivider,
  StyledDocumentBlockWrapper,
  StyledDocumentTitle,
  StyledLoaderWrapper,
} from './Modules.styles'
import { IModuleProps } from './types'
import { FaRegFileAlt } from 'react-icons/fa'
import { addToast } from 'features/toast/toastSlice'
import { ToastTypes } from 'lib/toast/Toast'

const moduleId = 'documents'

export const Documents: React.FC<IModuleProps> = ({
  openedModules,
  loanNumber,
  onToggleModule,
}): JSX.Element => {
  const dispatch = useDispatch()
  const [loader, setLoader] = useState<{ [key: string]: boolean }>({})

  const { bkiDocuments } = useSelector((store: GlobalState) => store.singleLoan)
  const { loadable, saasDocumentsList, pzDocumentsList } = useSelector(
    (store: GlobalState) => store.commonDocuments
  )

  const handleExpand = (): void => {
    onToggleModule(moduleId)
  }

  const isOpen = openedModules.includes(moduleId)

  const handleDownloadSaasDocument = async ({ title, consent_id }) => {
    setLoader({
      ...loader,
      [consent_id]: true,
    })

    try {
      const response = await CommonDocumentsService.downloadSaasDocument({ consent_id })

      const blob = response.data
      const downloadLink = window.document.createElement('a')
      downloadLink.href = window.URL.createObjectURL(
        new Blob([blob], {
          type: 'application/pdf',
        })
      )
      downloadLink.download = `${title} - ${loanNumber}.pdf`
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
      window.URL.revokeObjectURL(downloadLink.href)
      dispatch(
        addToast({
          type: ToastTypes.success,
          title: 'Успех!',
          description: `Файл успешно загружен`,
        })
      )
      setLoader({
        ...loader,
        [consent_id]: false,
      })
    } catch (error) {
      console.error('Ошибка при загрузке документа:', error)
      setLoader({
        ...loader,
        [consent_id]: false,
      })
      dispatch(
        addToast({
          type: ToastTypes.danger,
          title: 'Ошибка',
          description: `Файл не был загружен`,
        })
      )
    }
  }

  const handleDownloadPZDocument = async ({ title, id }) => {
    setLoader({
      ...loader,
      [id]: true,
    })

    try {
      const response = await CommonDocumentsService.downloadPZDocument({ id })

      const blob = response.data
      const downloadLink = window.document.createElement('a')
      downloadLink.href = window.URL.createObjectURL(
        new Blob([blob], {
          type: 'application/pdf',
        })
      )
      downloadLink.download = `${title} - ${loanNumber}.pdf`
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
      window.URL.revokeObjectURL(downloadLink.href)
      dispatch(
        addToast({
          type: ToastTypes.success,
          title: 'Успех!',
          description: `Файл успешно загружен`,
        })
      )
      setLoader({
        ...loader,
        [id]: false,
      })
    } catch (error) {
      console.error('Ошибка при загрузке документа:', error)
      setLoader({
        ...loader,
        [id]: false,
      })
      dispatch(
        addToast({
          type: ToastTypes.danger,
          title: 'Ошибка',
          description: `Файл не был загружен`,
        })
      )
    }
  }

  return (
    <Module.Wrapper isOpen={isOpen} id={moduleId}>
      <Module.Header onClick={handleExpand}>
        <Module.Actions isOpen={isOpen}>
          <Module.HeadTitle>Документы</Module.HeadTitle>
          <Module.Arrow isOpen={isOpen} />
        </Module.Actions>
      </Module.Header>
      <Module.ContentWrapper>
        <>
          <StyledBKIDocumentsWrapper>
            <b>Документы SaaS:</b>
            {(loadable?.saasDocumentsList || loadable?.pzDocumentsList) && <Module.LoaderBlock />}
            {saasDocumentsList?.map((v, index) => (
              <StyledDocumentBlockWrapper key={index}>
                <StyledDocumentTitle
                  loadable={loader[v?.consent_id]}
                  key={index}
                  onClick={() =>
                    loader[v?.consent_id]
                      ? null
                      : handleDownloadSaasDocument({
                          title: v?.consent_type,
                          consent_id: v?.consent_id,
                        })
                  }
                >
                  <FaRegFileAlt style={{ marginRight: '5px' }} />
                  {v?.consent_type} / {normalizeValues(v?.added_at, 'date')}
                </StyledDocumentTitle>
                {loader[v?.consent_id] && (
                  <StyledLoaderWrapper>
                    <SmallLoader extraSmall />
                  </StyledLoaderWrapper>
                )}
              </StyledDocumentBlockWrapper>
            ))}
            <br />
            <hr />
            <StyledDocsDivider />
            <b>Документы ПапаЗайм:</b>
            {loadable?.pzDocumentsList && <Module.LoaderBlock />}
            {pzDocumentsList?.map((v, index) => (
              <StyledDocumentBlockWrapper key={index}>
                <StyledDocumentTitle
                  loadable={loader[v?.id]}
                  key={index}
                  onClick={() =>
                    loader[v?.id]
                      ? null
                      : handleDownloadPZDocument({
                          title: v?.title,
                          id: v?.id,
                        })
                  }
                >
                  <FaRegFileAlt style={{ marginRight: '5px' }} />
                  {v?.title}
                </StyledDocumentTitle>
                {loader[v?.id] && (
                  <StyledLoaderWrapper>
                    <SmallLoader extraSmall />
                  </StyledLoaderWrapper>
                )}
              </StyledDocumentBlockWrapper>
            ))}
          </StyledBKIDocumentsWrapper>
        </>
      </Module.ContentWrapper>
    </Module.Wrapper>
  )
}
