/* eslint-disable @typescript-eslint/no-unused-vars */
import { rolesType } from 'constants/rtoles'
import { Switch, Route, BrowserRouter } from 'react-router-dom'

import {
  App,
  UnderwriterLists,
  UnderwriterLoanPage,
  ArmPage,
  AgentList,
  OldLoanRedirect,
  AgentLoan,
  SdlList,
  SdlDetails,
  ContractList,
  Contracts,
  AgentWaitingList,
  ClientsList,
  CreateLoan,
  BanList,
  BlackList,
  CollectorList,
  CollectorLoan,
  SettingsDocuments,
  Setting,
  // Settings,
  // ContractItem,
} from 'pages'
import { Layout, Sidebar, Header } from '../components'
import { StyledWrapper } from './routes-page.styles'

import {
  ARM_ROUTE,
  UNDERWRITER_LOANS_ROUTE,
  UNDERWRITER_LOAN_ID_ROUTE,
  AGENT_LOANS_ROUTE,
  ARM_LOAN_ID_ROUTE,
  AGENT_LOAN_ID_ROUTE,
  SDL_ROUTE_ROUTE,
  SDL_ROUTE_ID_ROUTE,
  CONTRACT_ID_ROUTE,
  CONTRACTS_ITEMS_ROUTE,
  WAITING_LOANS_ROUTE,
  CLIENTS_ROUTE,
  AGENT_LOANS_CREATE,
  UNDERWRITER_LOAN_BAN_LIST,
  OPERATOR_LOAN_ID_ROUTE,
  OPERATOR_LOANS_ROUTE,
  COLLECTOR_LOANS_ROUTE,
  COLLECTOR_LOAN_ID_ROUTE,
  SETTINGS_DOCUMENTS_ROUTE,
  SETTINGS_LIDS_ROUTE,
  SETTINGS_ROUTE,
  SETTINGS,
  UNDERWRITER_LOAN_BLACK_LIST,
} from '../constants'
import { MobileSidebarMenu } from 'components/Sidebar/MobileSidebarMenu'
import { TabsType } from 'components/Sidebar/types'
import { useState } from 'react'

const RoutesPage = (): JSX.Element => {
  const [opened, setOpened] = useState<boolean>(true)
  const [activeTab, setActiveTab] = useState<TabsType>(TabsType.menu)

  const props = { opened, setOpened, activeTab, setActiveTab }
  return (
    <BrowserRouter>
      <App>
        <Route exact path={[ARM_ROUTE, '/']}>
          <ArmPage />
        </Route>

        <StyledWrapper>
          <MobileSidebarMenu {...props} />
          <Sidebar {...props} />
          <Layout>
            <Header />
            <Switch>
              <Route path={ARM_LOAN_ID_ROUTE}>
                <OldLoanRedirect />
              </Route>
              <Route exact path={UNDERWRITER_LOAN_BAN_LIST}>
                <BanList />
              </Route>
              <Route exact path={UNDERWRITER_LOAN_BLACK_LIST}>
                <BlackList />
              </Route>
              <Route exact path={UNDERWRITER_LOANS_ROUTE}>
                <UnderwriterLists claims={rolesType.underwriter} />
              </Route>
              <Route exact path={UNDERWRITER_LOAN_ID_ROUTE}>
                <UnderwriterLoanPage claims={rolesType.underwriter} />
              </Route>
              <Route exact path={AGENT_LOANS_ROUTE}>
                <AgentList />
              </Route>
              <Route exact path={AGENT_LOANS_CREATE}>
                <CreateLoan />
              </Route>
              <Route exact path={WAITING_LOANS_ROUTE}>
                <AgentWaitingList />
              </Route>
              <Route exact path={CLIENTS_ROUTE}>
                <ClientsList />
              </Route>
              <Route path={AGENT_LOAN_ID_ROUTE}>
                <AgentLoan claims={rolesType.agent} />
              </Route>
              <Route exact path={CONTRACTS_ITEMS_ROUTE}>
                <ContractList />
              </Route>
              <Route path={CONTRACT_ID_ROUTE}>
                <Contracts claims={rolesType.agentContract} />
              </Route>
              <Route exact path={SDL_ROUTE_ROUTE}>
                <SdlList />
              </Route>
              <Route path={SDL_ROUTE_ID_ROUTE}>
                <SdlDetails />
              </Route>
              <Route exact path={OPERATOR_LOANS_ROUTE}>
                <UnderwriterLists claims={rolesType.operator} />
              </Route>
              <Route path={OPERATOR_LOAN_ID_ROUTE}>
                <UnderwriterLoanPage claims={rolesType.operator} />
              </Route>
              <Route exact path={COLLECTOR_LOANS_ROUTE}>
                <CollectorList />
              </Route>
              <Route exact path={SETTINGS_DOCUMENTS_ROUTE}>
                <SettingsDocuments />
              </Route>
              <Route exact path={SETTINGS_LIDS_ROUTE}>
                <Setting />
              </Route>
            </Switch>
          </Layout>
        </StyledWrapper>
      </App>
    </BrowserRouter>
  )
}

export default RoutesPage
