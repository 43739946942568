/* eslint-disable @typescript-eslint/no-unused-vars */
import { v4 as uuid } from 'uuid'
import { LoanType, SdlType } from 'features/loans/types'
import { AgentLoanType } from 'features/agentLoans/types'
import { ContractType } from 'features/contracts/types'
import { CollectorType } from 'features/collector/types'

export const STRUCTURE = {
  underwriter: [
    { title: 'Заявки в работе', value: LoanType.processing, id: uuid() },
    { title: 'Свободные заявки', value: LoanType.unassigned, id: uuid() },
    // { title: 'Предварительно одобрены', value: LoanType.pre_approved, id: uuid() },
    { title: 'Все заявки', value: LoanType.all, id: uuid() },
  ],
  operator: [{ title: 'Все заявки', value: LoanType.all, id: uuid() }],
  agent: [
    { title: 'Все', value: AgentLoanType.allApp, id: uuid() },
    // { title: 'Ожидают анкеты', value: AgentLoanType.waiting, id: uuid() },
    { title: 'Требуются док.', value: AgentLoanType.requiredDocs, id: uuid() },
    { title: 'В работе', value: AgentLoanType.atWork, id: uuid() },
    { title: 'На проверке', value: AgentLoanType.checking, id: uuid() },
    { title: 'Проверены', value: AgentLoanType.checked, id: uuid() },
    // { title: 'Предварительно одобрены', value: AgentLoanType.pre_approved, id: uuid() },
  ],
  contracts: [
    { title: 'Все', value: ContractType.allContracts, id: uuid() },
    { title: 'Активные', value: ContractType.active, id: uuid() },
    { title: 'Просроченные ', value: ContractType.overdue, id: uuid() },
    { title: 'День погашения ', value: ContractType.repayment_date, id: uuid() },
  ],
  collector: [
    { title: 'В работе', value: CollectorType.inWork, id: uuid() },
    { title: 'Свободные', value: CollectorType.free, id: uuid() },
    { title: 'Все', value: CollectorType.all, id: uuid() },
  ],
  waiting: [{ title: 'Все', value: 'waiting', id: uuid() }],
  clients: [{ title: 'Все', value: 'clients', id: uuid() }],
  sdl: [],
  banList: [],
  blackList: [
    { title: 'По паспорту', value: 0, id: uuid() },
    { title: 'По СНИЛС', value: 1, id: uuid() },
  ],
}
