import styled from 'styled-components/macro'

export const StyledLoaderPage = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StyledWrapper = styled.div`
  display: flex;
`

export const StyledBlockNav = styled.div`
  min-width: 240px;
  max-width: 240px;
  display: flex;
  flex-direction: column;

  @media (max-width: 1024px) {
    display: none;
  }
`

export const StyledContent = styled.div`
  width: 100%;
  height: 100%;
  background: #ffffff;
  overflow: hidden;
  max-height: calc(100vh - 145px);
  overflow-y: scroll;
  padding-bottom: 1500px;

  @media (max-width: 480px) {
    padding-bottom: 150px;
  }
`

export const StyledBTN = styled.div``

export const StyledNavContent = styled.div`
  width: 100%;
`
