/* eslint-disable indent */
import styled, { css } from 'styled-components/macro'
import { StyledButtonWrapper } from 'components/Button/Button.styles'
import { StyledInput } from 'components/Input/Input.styles'
import { THEME } from 'core/theme'

interface IStyledSearchBlock {
  showSearch: boolean
}

export const StyledSwitcherWrapper = styled.div`
  width: 350px;
  margin: 35px;
`

export const StyledTableWrapper = styled.div`
  width: 100%;

  height: calc(100vh - 208px);
  .infinite-scroll-component {
    overflow: inherit !important;
  }

  @media (max-width: 480px) {
    background: ${THEME.COLORS.SMOOTH};
    padding-top: 25px;

    .infinite-scroll-component__outerdiv {
      background: ${THEME.COLORS.SMOOTH};
      padding-bottom: 100px;
    }
  }
`

export const StyledSwitcher = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  background: #3b414f;
  border-radius: 16px;
  height: 44px;
  font-weight: 800;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  align-items: center;
  @media (max-width: 400px) {
    grid-template-columns: 1fr;
    height: 84px;
    font-size: 12px;
  }
`

export const StyledTab = styled.div<any>`
  z-index: 1;
  transition: all 0.2s linear;
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  ${({ active }) =>
    active &&
    css`
      cursor: default;
      color: #08003a;
    `}
  @media (max-width: 630px) {
    font-size: 12px;
  }
`
export const StyledActiveTab = styled.div<any>`
  position: absolute;
  top: 0;
  background: #f8cc46;
  border-radius: 14px;
  height: 38px;
  width: calc(50% - 1px);
  left: 2px;
  top: 3px;
  transition: all 0.2s linear;
  ${({ activeTab }) =>
    activeTab === 1 &&
    css`
      transform: translate(calc(100% - 2px), 0);
    `}
  ${({ activeTab }) =>
    activeTab === -1 &&
    css`
      display: none;
    `}

  @media (max-width: 400px) {
    ${({ activeTab }) =>
      activeTab === 1 &&
      css`
        transform: translate(0, calc(100% + 2px));
      `}
    height: 38px;
    width: calc(100% - 2px);
  }
`

export const StyledSearchContent = styled.div`
  border: 1px solid #e8e8e8;
  max-height: 0px;
  overflow: hidden;
  transition: max-height 0.2s linear;
  transition: padding 0.05s linear;
  display: grid;
  grid-template-columns: 180px minmax(250px, 1fr) 140px;
  padding: 0;

  @media (max-width: 480px) {
    grid-template-columns: 1fr 100px;
  }
`

export const StyledSearchBlock = styled.div<IStyledSearchBlock>`
  visibility: hidden;

  ${({ showSearch }) =>
    showSearch &&
    css`
      visibility: visible;
      ${StyledSearchContent} {
        max-height: 300px;
        padding: 24px 37px;

        @media (max-width: 480px) {
          max-height: auto;
          padding: 5px;
          gap: 5px;
        }
      }
    `}
`

export const StyledSearchWrapper = styled.div`
  position: relative;

  ${StyledInput} {
    border: 2px solid #f8cc46;

    @media (max-width: 480px) {
      font-weight: 500;
      border: 2px solid #ebeeff;
      box-shadow: none;
      border-radius: 0;
      padding: 15px !important;
      padding-right: 40px !important;
      border-radius: 10px !important;
      margin-right: 10px !important;

      & span {
        top: 0 !important;
      }

      &:hover {
        box-shadow: none;
      }
    }
  }
`

export const StyledActionsBlock = styled.div<{ disabled: boolean }>`
  ${StyledButtonWrapper} {
    height: 100%;
    width: 100%;
    text-align: center;
    border: 2px solid #f8cc46;
    box-sizing: border-box;
    border-radius: 16px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    margin-left: -14px;
    padding-left: 44px;
    font-size: 14px;

    @media (max-width: 480px) {
      padding: 0;
      margin: 0;
      border-radius: 10px;
      height: 50px;
      display: flex;
      justify-content: center;
      position: relative;
      top: 5px;
    }
  }

  ${({ disabled }) =>
    disabled &&
    css`
      ${StyledButtonWrapper} {
        background: #ffc0005c !important;
        color: #5a5a5a;
        &:hover {
          background: #ffc0005c !important;
        }
      }
    `}
`

export const StyledReset = styled.div`
  position: absolute;
  right: 20px;
  z-index: 900;
  bottom: 18px;
  cursor: pointer;

  @media (max-width: 480px) {
    right: 10px;
  }
`

export const StaledContainer = styled.div`
  @media (max-width: 480px) {
    background: ${THEME.COLORS.SMOOTH};
  }
`

export const StyledAddButton = styled.div`
  width: fit-content;
  background: #f8cc46;
  border-radius: 16px;
  padding: 10px 30px;
  display: flex;
  gap: 6px;
  align-items: center;
  cursor: pointer;
  font-weight: bold;

  &:hover {
    background: #fad35e;
  }

  & svg {
    width: 25px;
    height: 25px;
  }
`

export const StyledCommentsBlock = styled.div`
  padding: 0 0 30px 30px;
`

export const StyledCommentTitle = styled.p`
  margin: 30px 0;
  font-weight: bold;
`

export const StyledInputBlock = styled.input<{ value: string }>`
  background: ${THEME.COLORS.WHITE};
  border: 1px solid ${THEME.COLORS.LIGHTLY_GRAY};
  box-sizing: border-box;
  border-radius: 16px;
  color: ${THEME.COLORS.GRAY};
  padding: 19px 16px;
  width: 100%;
  max-width: 640px;
  height: 60px;
  resize: none;
  margin-bottom: 8px;

  &:focus {
    outline: none;
  }

  ${({ value }) =>
    value &&
    css`
      color: ${THEME.COLORS.DARK_BLUE};
      font-weight: 600;
    `}
`

export const StyledTextArea = styled.textarea<{ value: string }>`
  background: ${THEME.COLORS.WHITE};
  border: 1px solid ${THEME.COLORS.LIGHTLY_GRAY};
  box-sizing: border-box;
  border-radius: 16px;
  color: ${THEME.COLORS.GRAY};
  padding: 19px 16px;
  width: 100%;
  max-width: 640px;
  height: 120px;
  resize: none;

  &:focus {
    outline: none;
  }

  ${({ value }) =>
    value &&
    css`
      color: ${THEME.COLORS.DARK_BLUE};
      font-weight: 600;
    `}
`
export const StyledSelectBlock = styled.div`
  position: relative;
`

export const StyledResetFilter = styled.div`
  position: absolute;
  right: 20px;
  bottom: 20px;
  background: white;
  cursor: pointer;
`

export const StyledButtons = styled.div`
  display: flex;
  margin-top: 15px;
`
