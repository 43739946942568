/* eslint-disable arrow-body-style */
import { StyledUserInfo, StyledTitle, StyledValue } from './UserInformation.styles'

import { normalizeValues } from 'helpers'

interface UserInformationProps {
  fullName: string
  creditProduct: string
}

export const UserInformation: React.FC<UserInformationProps> = ({ fullName, creditProduct }) => {
  return (
    <StyledUserInfo>
      <StyledTitle>ФИО</StyledTitle>
      <StyledValue>{normalizeValues(fullName)}</StyledValue>
      <div
        style={{
          margin: '7.5px 0',
        }}
      />
      <StyledTitle>Продукт</StyledTitle>
      <StyledValue>{normalizeValues(creditProduct, 'productsTranslate')}</StyledValue>
    </StyledUserInfo>
  )
}
