/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable import/order */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable indent */
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuid } from 'uuid'

import { GlobalState } from 'index'
import { ToastTypes } from 'lib/toast/Toast'
import { ModalConfirm } from 'components'
import { Nullable } from 'commonTypes'

import { addToast } from 'features/toast/toastSlice'
import {
  getBlackListByPassport,
  getBlackListBySnils,
  removeFromBlackList,
} from 'features/blackList/extra'

import { ITableProps } from './types'
import { COLUMN_TEMPLATE } from './options'
import {
  StyledRowWrapper,
  StyledValue,
  StyledTitle,
  StyledActionTableWrapper,
  StyledUnLockActionTable,
  StyledUnlockActionTitle,
} from './Table.styles'

import { ReactComponent as UnlockSVG } from 'assets/unlock.svg'

export const Table = (props: ITableProps): JSX.Element => {
  const dispatch = useDispatch()
  const { loadable } = useSelector((store: GlobalState) => store.blackList)
  const { fio, iin, comment, record_date, user_snils, activeTab } = props

  console.log('user_snils', user_snils)

  interface IModal {
    show: boolean
    title: string
    description: string
    action?: Nullable<(adminComment: string) => void>
    style: string
  }

  const [showModal, setShowModal] = useState<IModal>({
    show: false,
    description: '',
    title: '',
    action: null,
    style: '',
  })

  const byPassportStructure = [
    {
      title: `${fio}`,
      id: uuid(),
    },
    {
      title: iin,
      id: uuid(),
    },
    {
      title: comment,
      id: uuid(),
    },
    {
      title: record_date,
      id: uuid(),
    },
    {
      title: ``,
      id: uuid(),
      action: true,
    },
  ]

  const bySnilsStructure = [
    {
      title: `${user_snils}`,
      id: uuid(),
    },
    {
      title: ``,
      id: uuid(),
      action: true,
    },
  ]

  const tableStructure = {
    0: byPassportStructure,
    1: bySnilsStructure,
  }

  const handleCloseModal = (): void => {
    setShowModal({
      show: false,
      title: '',
      action: null,
      style: '',
      description: '',
    })
  }

  const handleRemoveFromBlackList = ({ userFio }: { userFio: string }): void => {
    const byPassportAction = (): void => {
      dispatch(
        removeFromBlackList({
          passport: iin,
          onSuccess: () => {
            dispatch(
              addToast({
                id: uuid(),
                description: `Пользователь ${userFio} удален из блеклиста`,
                type: ToastTypes.success,
              })
            )
            dispatch(getBlackListByPassport({ search: userFio }))
          },
        })
      )
    }

    const bySnilsAction = (): void => {
      dispatch(
        removeFromBlackList({
          snils: user_snils,
          onSuccess: () => {
            dispatch(
              addToast({
                id: uuid(),
                description: `СНИЛС ${user_snils} удален из блеклиста`,
                type: ToastTypes.success,
              })
            )
            dispatch(getBlackListBySnils({ search: user_snils }))
          },
        })
      )
    }
    setShowModal({
      show: true,
      title: activeTab === 0 ? `Удалить пользователя` : `Удалить СНИЛС`,
      description:
        activeTab === 0
          ? `Удалить пользователя ${userFio} из блеклиста?`
          : `Удалить СНИЛС ${user_snils} из блеклиста?`,
      action: activeTab === 0 ? byPassportAction : bySnilsAction,
      style: 'success',
    })
  }

  return (
    <>
      <StyledRowWrapper indicate={false} template={COLUMN_TEMPLATE[activeTab]}>
        <ModalConfirm
          isOpen={showModal.show}
          onRequestClose={handleCloseModal}
          onClose={handleCloseModal}
          description={showModal.description}
          title={showModal.title}
          action={showModal.action}
          style={showModal.style}
          content={null}
        />

        {tableStructure[activeTab].map((column) => (
          <StyledValue key={column.id}>
            <StyledTitle select={null} pending={false}>
              {column.title}
            </StyledTitle>
            {column?.action && (
              <StyledActionTableWrapper>
                <StyledUnLockActionTable
                  onClick={() => handleRemoveFromBlackList({ userFio: fio })}
                >
                  <UnlockSVG />
                  <StyledUnlockActionTitle>Удалить из блеклиста</StyledUnlockActionTitle>
                </StyledUnLockActionTable>
              </StyledActionTableWrapper>
            )}
          </StyledValue>
        ))}
      </StyledRowWrapper>
    </>
  )
}
