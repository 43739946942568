/* eslint-disable object-shorthand */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { AxiosPromise } from 'axios'

import { BlackListByPassportList } from 'features/blackList/types'
import { ServiceBase } from './serviceBase'

export interface BlackListByPassport {
  full_name: string
  passport: string
  type: string
  comment: string
}

export interface BlackListBySnils {
  snils: string
}

export class ProjectBlacklistService extends ServiceBase {
  protected static USERS = 'users'

  public static getBlackListByPassport({
    search,
  }: {
    search: string
  }): AxiosPromise<BlackListByPassportList[]> {
    return this.post(`${this.USERS}/blacklist-kz/search`, {
      search,
    })
  }

  public static getBlackListBySnils({
    search,
  }: {
    search: string
  }): AxiosPromise<{ snils: string }[]> {
    return this.post(`${this.USERS}/blacklist-snils/search`, {
      search,
    })
  }

  public static removeFromBlackList({
    passport,
    snils,
  }: {
    passport?: string
    snils?: string
  }): AxiosPromise<undefined> {
    return this.delete(`${this.USERS}/blacklist/`, {
      data: {
        ...(passport && {
          passport,
        }),
        ...(snils && {
          snils,
        }),
      },
    })
  }

  public static addToBlackListByPassport({
    full_name,
    passport,
    type,
    comment,
  }: BlackListByPassport): AxiosPromise<undefined> {
    return this.post(`${this.USERS}/blacklist-kz`, {
      full_name,
      passport,
      type: Number(type),
      ...(comment && {
        comment,
      }),
    })
  }

  public static addToBlackListBySnils({ snils }: BlackListBySnils): AxiosPromise<undefined> {
    return this.post(`${this.USERS}/blacklist-snils/`, {
      snils,
    })
  }
}
