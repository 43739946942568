/* eslint-disable indent */
/* eslint-disable import/order */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable react/no-array-index-key */

import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { orderBy } from 'lodash'

import { GlobalState } from 'index'
import { IModuleProps } from './types'
import { ICommentsData } from 'features/loan/types'

import { Module } from 'components'
import { normalizeValues, useWindowSize } from 'helpers'

import {
  StyledCommentsContent,
  StyledMobileCommentsWrapper,
  StyledMobileComment,
  StyledMobileSection,
  StyledMobileValue,
  StyledMobileDescription,
  StyledMobileCommentInfo,
  StyledMessagesContent,
  StyledMobileMessagesInfo,
} from './Modules.styles'

const moduleId = 'messages'
interface IMessagesProps extends IModuleProps {
  loan_id: string
}

export const Messages: React.FC<IMessagesProps> = ({
  openedModules,
  onToggleModule,
}): JSX.Element => {
  const {
    userId,
    messagesData: { data, pending },
  } = useSelector((state: GlobalState) => state.singleLoan)
  const user = useSelector((state: GlobalState) => state.auth.user)
  const isOpen = openedModules.includes(moduleId)
  const [messages, setMessages] = useState<ICommentsData[]>([])
  const { width } = useWindowSize()

  const handleExpand = (): void => {
    onToggleModule(moduleId)
  }

  useEffect(() => {
    if (data && data.length > 0) {
      const filtered = [...data].sort(
        (a: any, b: any) => new Date(a.added_at).getTime() - new Date(b.added_at).getTime()
      )
      setMessages(filtered)
    }
  }, [data])

  return (
    <Module.Wrapper isOpen={isOpen} id={moduleId}>
      <Module.Header onClick={handleExpand}>
        <Module.Actions isOpen={isOpen}>
          <Module.HeadTitle>
            Сообщения
            {messages.length === 0 ? (
              <span style={{ color: 'gray', opacity: '0.5' }}> / отсутствует</span>
            ) : null}
          </Module.HeadTitle>
          <Module.Arrow isOpen={isOpen} />
        </Module.Actions>
      </Module.Header>
      <Module.ContentWrapper>
        {width >= 480 ? (
          <StyledMessagesContent>
            <TableHead />
            {messages.length > 0 &&
              orderBy(messages, ['added_at'], ['desc']).map((item) => (
                <Table {...item} key={item?.added_at} user={user} userId={userId} />
              ))}
          </StyledMessagesContent>
        ) : (
          <StyledMobileCommentsWrapper>
            {messages.length > 0 &&
              orderBy(messages, ['added_at'], ['desc']).map((item) => (
                <MobileTable {...item} key={item?.added_at} user={user} userId={userId} />
              ))}
          </StyledMobileCommentsWrapper>
        )}
      </Module.ContentWrapper>
    </Module.Wrapper>
  )
}

const MobileTable = (props): JSX.Element => {
  const { sender, message_body, send_date, target, sended_date, send_status } = props

  return (
    <StyledMobileComment>
      <StyledMobileSection>
        <StyledMobileMessagesInfo>
          <div>
            <StyledMobileDescription>Дата создания</StyledMobileDescription>
            <StyledMobileValue>{normalizeValues(send_date, 'full_date')}</StyledMobileValue>
          </div>
          <div>
            <StyledMobileDescription>Телефон/Email</StyledMobileDescription>
            <StyledMobileValue>{target}</StyledMobileValue>
          </div>
          <div>
            <StyledMobileDescription>Статус</StyledMobileDescription>
            <StyledMobileValue>{send_status}</StyledMobileValue>
          </div>
          <div>
            <StyledMobileDescription>Дата отправки</StyledMobileDescription>
            <StyledMobileValue>{normalizeValues(sended_date, 'full_date')}</StyledMobileValue>
          </div>
          <div>
            <StyledMobileDescription>Отправитель</StyledMobileDescription>
            <StyledMobileValue>{sender}</StyledMobileValue>
          </div>
        </StyledMobileMessagesInfo>
      </StyledMobileSection>
      <div>
        <span style={{ fontSize: '12px' }}>{normalizeValues(message_body)}</span>
      </div>
    </StyledMobileComment>
  )
}

const TableHead = (): JSX.Element => (
  <>
    <Module.TableColumn column={1}>
      <Module.TableTitle>Дата создания</Module.TableTitle>
    </Module.TableColumn>
    <Module.TableColumn column={1}>
      <Module.TableTitle>Телефон/Email</Module.TableTitle>
    </Module.TableColumn>
    <Module.TableColumn column={1}>
      <Module.TableTitle>Статус</Module.TableTitle>
    </Module.TableColumn>
    <Module.TableColumn column={1}>
      <Module.TableTitle>Дата отправки</Module.TableTitle>
    </Module.TableColumn>
    <Module.TableColumn column={1}>
      <Module.TableTitle>Отправитель</Module.TableTitle>
    </Module.TableColumn>
    <Module.TableColumn column={1}>
      <Module.TableTitle>Текст</Module.TableTitle>
    </Module.TableColumn>
  </>
)

const Table = (props): JSX.Element => {
  const { sender, message_body, send_date, target, sended_date, send_status } = props

  return (
    <>
      <Module.TableColumn column={1}>
        <Module.TableValue>{normalizeValues(send_date, 'full_date')}</Module.TableValue>
      </Module.TableColumn>
      <Module.TableColumn column={1}>
        <Module.TableValue>{target}</Module.TableValue>
      </Module.TableColumn>
      <Module.TableColumn column={1}>
        <Module.TableValue>{send_status}</Module.TableValue>
      </Module.TableColumn>
      <Module.TableColumn column={1}>
        <Module.TableValue>{normalizeValues(sended_date, 'full_date')}</Module.TableValue>
      </Module.TableColumn>
      <Module.TableColumn column={1}>
        <Module.TableValue>{sender}</Module.TableValue>
      </Module.TableColumn>
      <Module.TableColumn column={1}>
        <Module.TableValue>
          <span style={{ marginRight: '60px' }}>{normalizeValues(message_body)}</span>
        </Module.TableValue>
      </Module.TableColumn>
    </>
  )
}
